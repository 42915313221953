export const SEARCH_SETTINGS_TAB_PARAM = 'activeSearchSettingsTab';

export const searchResultsMainArticleId =
  '9c66c4b1-f426-4211-a530-89aae998dbb4';
export const searchResultsSettingsArticleId =
  'c2dbdae6-45a4-4b98-be29-7569ac16d11';
export const searchResultsDisplayArticleId =
  'b754cb65-17d9-4269-9578-1466e28171a4';
export const searchResultsLayoutArticleId =
  'edd158ff-fbfc-48af-8b14-5435bf9956e1';
export const searchResultsDesignArticleId =
  '30101c64-3978-417c-b152-d655ae1eb4f5';
