import { ComponentRef } from '@wix/editor-platform-sdk-types';

import { connectSearchBox, isComponentConnected } from '../searchAppController';
import { initSearchBoxSuggestions } from '../searchBox';
import { EditorAppContext } from '../types';

export async function onSearchBoxAdded(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  if (!(await isComponentConnected(appContext, componentRef))) {
    await connectSearchBox(appContext, componentRef);
  }
  await initSearchBoxSuggestions(appContext, componentRef);
}
