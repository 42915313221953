import { AnyComponentAddedToStage } from '@wix/editor-platform-sdk-types';

import { isSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import { onSearchBoxAdded } from '../onSearchBoxAdded';

export async function onAnyComponentAddedToStage(
  appContext: EditorAppContext,
  payload: AnyComponentAddedToStage['eventPayload'],
) {
  if (
    // NOTE: uncomment this code when problem with controllers will be resolved
    // payload.appDefinitionId === editor.appDefinitionId &&
    await isSearchBox(appContext, payload.compRef)
  ) {
    const { fedops } = appContext;
    fedops.interactionStarted(Interaction.SearchBoxAdded);
    await onSearchBoxAdded(appContext, payload.compRef);
    fedops.interactionEnded(Interaction.SearchBoxAdded);
  }
}
