import { AppManifestBuilder } from '@wix/yoshi-flow-editor';

import { openSearchResultsWidgetSettingsPanel } from '../searchResultsWidgetSettings';
import { ControllerType, EditorAppContext, WixCodeRole } from '../types';

export function overrideSearchBoxGfpp(
  appContext: EditorAppContext,
  appManifestBuilder: AppManifestBuilder,
): AppManifestBuilder {
  const { translate, reportError } = appContext;

  const configureActions = (connectedComponentsBuilder: any) => {
    return connectedComponentsBuilder.gfpp().set('mainAction2', {
      label: translate('searchBox.gfpp.manageSearchResults'),
      async onClick() {
        try {
          await openSearchResultsWidgetSettingsPanel(appContext);
        } catch (e) {
          reportError(e);
        }
      },
    });
  };

  return appManifestBuilder
    .configureController(ControllerType.SearchApp, (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        WixCodeRole.SearchBox,
        (connectedComponentsBuilder) => {
          configureActions(connectedComponentsBuilder);
        },
      );
    })
    .configureWidget(ControllerType.SearchApp, (widgetBuilder) => {
      widgetBuilder.gfpp().set('connect', {
        behavior: 'HIDE',
      });
      widgetBuilder.configureConnectedComponents(
        WixCodeRole.SearchBox,
        (connectedComponentsBuilder) => {
          configureActions(connectedComponentsBuilder).set('connect', {
            behavior: 'HIDE',
          });
        },
      );
    });
}
