import { searchSuggestionsAppDefId } from '../constants/appDefIds';
import { EditorSDK } from '@wix/editor-platform-sdk-types';

export const isSearchSuggestionsAppInstalled = async (editorSDK: EditorSDK) => {
  return editorSDK.application.isApplicationInstalled('', {
    appDefinitionId: searchSuggestionsAppDefId,
  });
};

export const installSearchSuggestionsModal = async (editorSDK: EditorSDK) => {
  try {
    return await editorSDK.application.add('', {
      appDefinitionId: searchSuggestionsAppDefId,
    });
  } catch (_) {}
};
