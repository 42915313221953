import { SLOTS } from '../../lib/slots';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { createSettingsClient } from '@wix/search-settings-client';
import { getSlot } from '../getSlot';
import { productsAppDefId } from '../constants/appDefIds';
import { productWidgetComponentId } from '../constants/componentIds';
import { EditorSDK } from '@wix/editor-platform-sdk-types';
import { EditorAppContext } from '../types';

export const installProductsWidget = async ({
  editorSDK,
  appDefinitionId,
  flowAPI,
}: {
  editorSDK: EditorSDK;
  appDefinitionId: string;
  flowAPI: FlowAPI;
}) => {
  const productsSlot = await getSlot(editorSDK, SLOTS.products.id);

  if (productsSlot && !productsSlot.pluginInfo) {
    try {
      await editorSDK.document.transactions.runAndWaitForApproval(
        appDefinitionId,
        async () => {
          await editorSDK.tpa.widgetPlugins.addWidgetPlugin(appDefinitionId, {
            slotCompRef: productsSlot.compRef!,
            widgetPluginPointer: {
              appDefinitionId: productsAppDefId,
              widgetId: productWidgetComponentId,
            },
          });
        },
      );
    } catch (e) {
      // TODO: report error
      // eslint-disable-next-line no-console
      console.error(e);
    }
    await setProductSlotInstalled(flowAPI);
  }
};

export const isProductSlotInstalled = async (flowAPI: FlowAPI) => {
  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  const appSettings = await settingsClient.getSaved();
  return appSettings.isProductSlotInstalled;
};

export const setProductSlotInstalled = async (flowAPI: FlowAPI) => {
  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  await settingsClient.set('isProductSlotInstalled', true);
};

export const resetProductSlotInstalled = async (
  appContext: EditorAppContext,
) => {
  const { flowAPI, reportError } = appContext;
  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  try {
    await settingsClient.set('isProductSlotInstalled', false);
  } catch (error) {
    reportError(error);
  }
};
