import {
  ComponentDefinition,
  ComponentRef,
} from '@wix/editor-platform-sdk-types';
import { ComponentLayout, EditorAppContext } from './types';
import { getAppWidgetWrappedSearchButtonDefinition } from './componentDefinitions';
import { searchButtonAddedSrc99Evid411 } from '@wix/bi-logger-wix-code-data/v2';

const SEARCH_BUTTON_SIZE = {
  width: 42,
  height: 42,
};

export async function isSearchButton(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<boolean> {
  const { appDefinitionId, editorSDK, reportError } = appContext;
  try {
    const componentType = await editorSDK.components.getType(appDefinitionId, {
      componentRef,
    });
    return componentType === 'wixui.SearchButton';
  } catch (error) {
    reportError(error);
  }
  return false;
}

export async function addSearchButton(
  appContext: EditorAppContext,
): Promise<ComponentRef | undefined> {
  const { editorSDK, appDefinitionId, reportError, flowAPI } = appContext;
  const headerRef = await editorSDK.siteSegments.getHeader(appDefinitionId);
  const headerLayout = (await editorSDK.components.layout.get(appDefinitionId, {
    componentRef: headerRef,
  })) as ComponentLayout;

  const isClassicEditor = flowAPI.environment.isClassicEditor;

  const searchButtonLayout = {
    x: headerLayout.bounding.width - SEARCH_BUTTON_SIZE.width,
    y:
      headerLayout.bounding.height > SEARCH_BUTTON_SIZE.height
        ? Math.round(
            headerLayout.bounding.height / 2 - SEARCH_BUTTON_SIZE.height / 2,
          )
        : 0,
    width: SEARCH_BUTTON_SIZE.width,
    height: SEARCH_BUTTON_SIZE.height,
  };

  const appWidgetLayouts: ComponentDefinition['layouts'] = {
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
    componentLayout: {
      type: 'ComponentLayout',
      width: {
        type: 'px',
        value: SEARCH_BUTTON_SIZE.width,
      },
      height: {
        type: 'px',
        value: SEARCH_BUTTON_SIZE.height,
      },
      minHeight: {
        type: 'px',
        value: 10,
      },
      maxHeight: {
        type: 'px',
        value: 99999,
      },
      hidden: false,
    },
    itemLayout: {
      id: 'app-widget-item-layout',
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      margins: {
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 200,
        },
      },
      justifySelf: 'end',
      alignSelf: 'center',
    },
    type: 'SingleLayoutData',
  };

  const searchButtonLayouts: ComponentDefinition['layouts'] = {
    type: 'SingleLayoutData',
    componentLayout: {
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 3.28125,
      },
      height: {
        type: 'percentage',
        value: 47.19101123595506,
      },
      hidden: false,
    },
    itemLayout: {
      id: 'search-button-item-layout',
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      margins: {
        top: {
          type: 'px',
          value: 0,
        },
        left: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
      },
      justifySelf: 'center',
      alignSelf: 'center',
    },
  };

  const componentDefinition = getAppWidgetWrappedSearchButtonDefinition({
    appContext,
    layoutProps: {
      layout: searchButtonLayout,
      buttonLayouts: isClassicEditor ? undefined : searchButtonLayouts,
      appWidgetLayouts: isClassicEditor ? undefined : appWidgetLayouts,
    },
  });
  try {
    const searchButton = await editorSDK.components.add(appDefinitionId, {
      pageRef: headerRef,
      componentDefinition,
    });
    appContext.flowAPI.bi?.report(
      searchButtonAddedSrc99Evid411({ button_type: 'searchButton' }),
    );
    return searchButton;
  } catch (error) {
    reportError(error);
  }
}
