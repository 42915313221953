import { EditorAppContext } from '../types';
import {
  installSearchSuggestionsModal,
  isSearchSuggestionsAppInstalled,
} from '../searchSuggestionsModal';
import { SEARCH_RESULTS_PAGE_URL_SLUG } from '../constants';
import { getSearchResultsPage } from '../searchResults';
import { installProductsWidget } from '../installProductsWidget/installProductsWidget';

export const migrateToNewSearch = async (appContext: EditorAppContext) => {
  const { editorSDK, appDefinitionId } = appContext;
  const isInstalled = await isSearchSuggestionsAppInstalled(editorSDK);

  if (isInstalled) {
    return;
  }

  await installSearchSuggestionsModal(editorSDK);

  const searchResultsPageRef = await getSearchResultsPage(appContext);

  await editorSDK.pages.data.update(appDefinitionId, {
    pageRef: searchResultsPageRef,
    data: {
      pageUriSEO: SEARCH_RESULTS_PAGE_URL_SLUG,
      appDefinitionId,
    },
  });

  await installProductsWidget({
    editorSDK: appContext.editorSDK,
    appDefinitionId: appContext.appDefinitionId,
    flowAPI: appContext.flowAPI,
  });
};
