import { SEARCH_LIGHTBOX_TPA_PAGE_ID } from '@wix/site-search-common';
import { EditorAppContext } from '../types';

export async function openSearchModalSettingsPanel(
  appContext: EditorAppContext,
) {
  const { editorSDK, appDefinitionId } = appContext;
  const popups = await editorSDK.pages.popupPages.getApplicationPopups(
    appDefinitionId,
  );
  // This is temporary implementation, we will open some settings panel once it's available
  const lightbox = popups.find(
    (popup) => popup.tpaPageId === SEARCH_LIGHTBOX_TPA_PAGE_ID,
  )!;

  await editorSDK.pages.popupPages.open(appDefinitionId, {
    popupRef: { id: lightbox.id!, type: 'DESKTOP' },
  });
}
