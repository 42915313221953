export const configurePages = async (pagesBuilder: any) => {
  return pagesBuilder
    .addSettingsTab(
      {
        title: 'Page Info',
        type: 'page_info',
      },
      {
        title: 'Layout',
        type: 'layout',
      },
      {
        title: 'Permissions',
        type: 'permissions',
      },
      {
        title: 'SEO',
        type: 'seo',
      },
    )
    .addAction({
      type: 'page_rename',
    });
};
