import { IGetDataByAppDef } from '@wix/editor-platform-sdk-types';

import { SEARCH_SETTINGS_TAB_PARAM } from '../../components/SearchResults/Settings/constants';
import { SettingsTab } from '../../components/SearchResults/Settings/types';
import { EditorAppContext } from '../types';
import componentConfig from '../../components/SearchResults/.component.json';
import { getComponentRef } from '../getComponentRef';

export async function openSearchResultsWidgetSettingsPanel(
  appContext: EditorAppContext,
  activeSearchSettingsTab = SettingsTab.Main,
) {
  const { editorSDK, appDefinitionId, translate } = appContext;

  if (activeSearchSettingsTab === SettingsTab.Main) {
    await editorSDK.editor.openSettingsPanel(appDefinitionId, {
      widgetId: componentConfig.id,
    });
    return;
  }

  const appData = (await editorSDK.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  )) as IGetDataByAppDef;

  const [urlPrefix, componentRef] = await Promise.all([
    getSettingsModalUrl(appData),
    getComponentRef(editorSDK),
  ]);

  if (componentRef && urlPrefix) {
    const width = 404;
    const url = `${urlPrefix}?${[
      `${SEARCH_SETTINGS_TAB_PARAM}=${activeSearchSettingsTab}`,
      `width=${width}`,
    ].join('&')}`;

    await editorSDK.editor.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [componentRef],
    });

    await editorSDK.editor.openComponentPanel(appDefinitionId, {
      componentRef,
      height: 528,
      title: translate('searchResults.settings.modal.title'),
      type: editorSDK.editor.PanelType.Settings,
      url,
      width,
    });
  }
}

const getSettingsModalUrl = (
  appData: IGetDataByAppDef & {
    widgets?: {
      [key: string]: { settings?: { url?: string; urlV2?: string } };
    };
  },
): string | undefined => {
  const widgetSettings = appData?.widgets?.[componentConfig.id]?.settings;

  if (widgetSettings) {
    return widgetSettings.urlV2 || widgetSettings.url;
  }
};
