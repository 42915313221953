import { SLOTS } from '../../lib/slots';
import { searchResultsMainArticleId } from '../../components/SearchResults/Settings/constants';

export const configureWidget = (widgetBuilder: any) => {
  widgetBuilder.slots().set({
    [SLOTS.products.id]: {
      displayName: SLOTS.products.displayName,
      interfaces: [],
    },
  });
  widgetBuilder
    .gfpp()
    .set('widgetPlugins', {
      behavior: 'HIDE',
    })
    .set('help', {
      id: searchResultsMainArticleId,
    });
};
