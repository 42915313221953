import { EditorSDK } from '@wix/editor-platform-sdk-types';
import { searchResultsAppDefId } from './constants/appDefIds';
import componentConfig from '../components/SearchResults/.component.json';

export const getComponentRef = async (editorSDK: EditorSDK) => {
  const appComponents = await editorSDK!.tpa.app.getAllComps(
    'token',
    searchResultsAppDefId,
  );
  const component = appComponents.find(
    (c) => c.widgetId === componentConfig.id,
  );
  if (component) {
    return editorSDK.document.components.getById('token', {
      id: component.id,
    });
  }
  return undefined;
};
