import { EditorAppContext, ComponentType } from '../types';
import { getSearchAppControllers } from '../searchAppController';

// Before migration of SearchBox to AppWidget - we had an AppController component that
// was manually added to site header (and connected to each SearchBox). While invisible,
// this controller has a size and was set to fixedPosition = true to make sure it is not
// blocking site header from being resized. However, there was a bug in Thunderbolt where
// our fixedPosition was not applied. This migration code fixes websites with this issue.
export async function fixAppControllerLayout(appContext: EditorAppContext) {
  const { appDefinitionId, editorSDK } = appContext;
  const appControllers = await getSearchAppControllers(appContext);

  for (const controllerRef of appControllers) {
    const componentsType = await editorSDK.components.getType(appDefinitionId, {
      componentRef: controllerRef,
    });

    // We don't want to update layout for AppControllers managed by AppWidget (they do
    // automatically resize to inner search box dimensions).
    if (componentsType === ComponentType.AppWidget) {
      continue;
    }

    const controllerLayout = await editorSDK.components.layout.get(
      appDefinitionId,
      {
        componentRef: controllerRef,
      },
    );

    // Skip already fixed/updated controllers.
    if (controllerLayout.fixedPosition) {
      continue;
    }

    await editorSDK.components.layout.update(appDefinitionId, {
      componentRef: controllerRef,
      layout: {
        fixedPosition: true,
      },
    });
  }
}
