import type {
  EditorReadyFn,
  GetAppManifestFn,
  HandleActionFn,
} from '@wix/yoshi-flow-editor';
import {
  AppExposedApis,
  EditorSDK,
  EventType,
} from '@wix/editor-platform-sdk-types';
import { Spec } from '@wix/site-search-common';

import { ControllerType, EditorAppContext } from './types';
import { Interaction } from './constants/interaction';
import { doFirstInstall } from './doFirstInstall';
import { getAllSearchBoxes } from './searchBox';
import { reconnectSearchBoxes } from './reconnectSearchBoxes';
import { patchInputFontProperty } from './patchInputFontProperty';
import { fixAppControllerLayout } from './fixAppControllerLayout';
import { onRemoveApp } from './onRemoveApp';
import { onAnyComponentAddedToStage } from './onAnyComponentAddedToStage';
import { reportError } from '../lib/errors';
import { removeDuplicateControllers } from './searchAppController';
import { getConnectedSearchBoxes } from './getConnectedSearchBoxes';
import { getSearchResultsPage } from './searchResults';
import componentConfig from '../components/SearchResults/.component.json';
import {
  installProductsWidget,
  isProductSlotInstalled,
} from './installProductsWidget/installProductsWidget';
import { SEARCH_RESULTS_PAGE_URL_SLUG } from './constants';
import {
  configureAppManager,
  configurePages,
  configurePagesTab,
  overrideSearchComponentsGfpp,
} from './manifestConfiguration';
import { configureWidget } from './manifestConfiguration/configureWidget';

interface EditorPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  handleAction: HandleActionFn;
  exports: (editorSDK: EditorSDK) => AppExposedApis;
}

export function createEditorPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;

  return {
    async editorReady(editorSDK, appDefinitionId, options, flowAPI) {
      const { firstInstall } = options;
      const { experiments, fedops, errorMonitor, environment, translations } =
        flowAPI;
      const { isEditorX, isClassicEditor } = environment;

      fedops.interactionStarted(Interaction.EditorReady);

      const isSlotInstalled = await isProductSlotInstalled(flowAPI);
      appContext = {
        flowAPI,
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        isEditorX: environment.isEditorX,
        isClassicEditor,
        reportError(error: any) {
          reportError(errorMonitor, error, {
            firstInstall,
            isEditorX,
          });
        },
        isProductSlotInstalled: isSlotInstalled,
      };

      await editorSDK.addEventListener(
        EventType.anyComponentAddedToStage,
        async (event) => {
          try {
            await onAnyComponentAddedToStage(appContext, event.detail);
          } catch (e) {
            appContext.reportError(e);
          }
        },
      );

      if (firstInstall) {
        await doFirstInstall(appContext);
      } else {
        await fixAppControllerLayout(appContext);
      }

      const searchResults = await getSearchResultsPage(appContext);

      await editorSDK.pages.data.update(appDefinitionId, {
        pageRef: searchResults,
        data: {
          pageUriSEO: SEARCH_RESULTS_PAGE_URL_SLUG,
          managingAppDefId: appDefinitionId,
        },
      });

      const isAppInstalled = await editorSDK.application.isApplicationInstalled(
        '',
        {
          appDefinitionId,
        },
      );

      if (!searchResults && isAppInstalled) {
        await editorSDK.application.uninstall(appDefinitionId, {
          openConfirmation: false,
        });
        return;
      }

      const allSearchBoxes = await getAllSearchBoxes(appContext);

      if (isClassicEditor) {
        /**
         * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
         * Investigate if it's still really needed.
         * https://jira.wixpress.com/browse/SER-1310
         */
        await reconnectSearchBoxes(appContext, allSearchBoxes);
      }

      const connectedSearchBoxes = await getConnectedSearchBoxes(appContext);

      // There is a problem where some sites have duplicated controllers for searchboxes
      // This will remove or disconnect duplicated controllers when user loads the editor
      if (connectedSearchBoxes.length > allSearchBoxes.length) {
        for (const componentRef of allSearchBoxes) {
          await removeDuplicateControllers(appContext, componentRef);
        }
      }

      // TODO Should we run this part of code, if none of components registered? i.e. allSearchBoxes.length === 0
      await Promise.all(
        allSearchBoxes.map((sb) => patchInputFontProperty(appContext, sb)),
      );
      fedops.interactionEnded(Interaction.EditorReady);
    },
    async getAppManifest({ appManifestBuilder }, _, __, flowAPI) {
      return overrideSearchComponentsGfpp(appContext, appManifestBuilder)
        .configureController(ControllerType.SearchApp, (controllerBuilder) => {
          controllerBuilder.set({
            visibility: 'NEVER',
          });
        })
        .configureController(
          ControllerType.SearchButton,
          (controllerBuilder) => {
            controllerBuilder.set({
              visibility: 'NEVER',
            });
          },
        )
        .configureManagementActions((managementActionsBuilder) => {
          return configureAppManager(appContext, managementActionsBuilder);
        })
        .configureWidget(componentConfig.id, (widgetBuilder) => {
          return configureWidget(widgetBuilder);
        })
        .configurePagesTab((pagesTabBuilder) => {
          return configurePagesTab(appContext, pagesTabBuilder);
        })
        .configurePages((pagesBuilder) => {
          return configurePages(pagesBuilder);
        })
        .build();
    },

    async handleAction({ type }) {},

    exports(editorSDK) {
      return {
        editor: {
          appInstalled: async ({ appDefinitionId }) => {
            if (
              !appContext.isClassicEditor ||
              appContext.experiments.enabled(Spec.NewSearchOnClassicEditor)
            ) {
              await installProductsWidget({
                editorSDK,
                appDefinitionId: appContext.appDefinitionId,
                flowAPI: appContext.flowAPI,
              });
            }
            if (appContext.experiments.enabled(Spec.SkipNavigationStep)) {
              const searchResultsPageRef = await getSearchResultsPage(
                appContext,
              );
              await editorSDK.pages.navigateTo(appDefinitionId, {
                pageRef: searchResultsPageRef,
              });
            }
          },
          beforeRemoveApp: async () => {
            await onRemoveApp(appContext);
          },
        },
      };
    },
  };
}
