import { getComponentRef } from './getComponentRef';
import { EditorSDK } from '@wix/editor-platform-sdk-types';

export const getSlot = async (editorSDK: EditorSDK, slotId: string) => {
  const searchCompRef = await getComponentRef(editorSDK);
  if (searchCompRef) {
    const widgetSlots = await editorSDK!.tpa.widgetPlugins.getWidgetSlots(
      'token',
      {
        widgetRef: searchCompRef,
      },
    );
    return widgetSlots.find((slot) => slot.placement.slotId === slotId);
  }
};
